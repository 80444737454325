.verification-input-character {
    border-radius: 7px;
    border: 1px solid #D9D9D9;;
    background: #F4F4F4;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 24px;
    font-weight: 500;

    margin-right: 24px;
}

.verification-input-character-error {
    background: #EF44440F;
    border-color: #EF4444;
}

.verification-input-character:last-child {
    margin-right: 0;
}


.verification-input-container {
    width: 100%;
    height: 100px;
}

.vi__wrapper {
    width: 100%;
}